import React, { Fragment } from 'react';
import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import Flip from 'react-reveal/Flip';

import Layout from '../components/layout';
import SEO from '../components/seo';

export const pageQuery = graphql`
  query {
    archives: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/films/**/*.md" }
        frontmatter: { category: { eq: "Archives" } }
      }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          html
          excerpt
          frontmatter {
            title
            meta
            affiche {
              childImageSharp {
                fluid(quality: 100, maxWidth: 400, maxHeight: 540) {
                  ...GatsbyImageSharpFluid_tracedSVG
                }
              }
            }
          }
        }
      }
    }
  }
`;

const ArchivePage = ({ data }) => (
  <Layout>
    <SEO
      title="Archives - Cinelangues"
      description="Retrouvez tous nos films."
      pathname="/archives-cinema-latin/"
    />
    <div className="page">
      <div className="container jumbotron">
        <div className="row">
          <div className="col-sm-12 col-md-8 header-content my-auto">
            <h1 className="ml-2">
              <i className="fa fa-box ml-2 mr-2"></i>
              Archives
            </h1>
          </div>
        </div>
      </div>
      <div className="container films">
        <div className="row">
          <div className="col-lg-12">
            <div className="row no-gutter">
              {data.archives.edges.map(({ node: post }) => (
                <div key={post.id} className="col-6 col-sm-6 col-md-4 col-lg-3">
                  <Flip right cascade>
                    <div className="card">
                      <Link to={post.fields.slug}>
                        <Img
                          fluid={post.frontmatter.affiche.childImageSharp.fluid}
                          alt={post.frontmatter.title}
                        />
                        <div className="text">
                          <div className="row no-gutter">
                            <h2 className="card-title text-capitalize col-12 col-sm-12">
                              {post.frontmatter.title}
                            </h2>
                            <div className="col-lg-12">
                              <small>{post.frontmatter.meta}</small>
                            </div>
                            <p>
                              <div
                                dangerouslySetInnerHTML={{ __html: post.excerpt }}
                              ></div>
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </Flip>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default ArchivePage;
